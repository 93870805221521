import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// 导入全局样式
import "@/assets/scss/index.scss";

Vue.config.productionTip = false;

Vue.use(ElementUI);

// 全局函数，根据localStorage中的role判断是否有权限，参数为权限名
Vue.prototype.isAuth = function (permission) {
  let role = localStorage.getItem("role");
  return role === permission;
};

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
