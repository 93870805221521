export default [
  {
    name: "Login",
    path: "/login",
    component: () => import("@/components/Login.vue"),
    meta: { title: "登录" },
  },
  {
    name: "Home",
    path: "/",
    component: () => import("@/components/Home.vue"),
    meta: { title: "首页" },
    children: [
      {
        name: "HomePage",
        path: "/home",
        component: () => import("@/views/HomePage.vue"),
        meta: { title: "首页" },
      },
      {
        name: "Specimen",
        path: "/specimen",
        component: () => import("@/views/Specimen.vue"),
        meta: { title: "标本管理" },
      },
      {
        name: "SpecimenData",
        path: "/specimen-data",
        component: () => import("@/views/SpecimenData.vue"),
        meta: { title: "标本数据" },
      },
      {
        name: "SpecimenDetail",
        path: "/specimen-detail",
        component: () => import("@/views/SpecimenDetail.vue"),
        meta: { title: "标本详情" },
      },
      {
        name: "UserManagement",
        path: "/user",
        component: () => import("@/views/UserManagement.vue"),
        meta: { title: "用户管理" }
      },
      {
        name: "Search",
        path: "/search",
        component: () => import("@/views/Search.vue"),
        meta: { title: "标本搜索" }
      }
    ],
  },
  {
    name: "NullPage",
    path: "/404",
    component: () => import("@/components/NullPage.vue"),
    meta: { title: "404" },
  },
];
