import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
});

// 导航守卫
router.beforeEach((to, from, next) => {
  if (to.name != "Login") {
    let role = localStorage.getItem("role");//获取权限
    let token = localStorage.getItem("token");
    if (role == null || role == "" || token == null || token == "") {
      next({
        name: "Login",
      });
    }
    //如果访问的页面是/user，但是权限不是admin，则跳转到/404
    if (to.name == "UserManagement" && role != "0") {
      next({
        name: "NullPage",
      });
    }
  } else {
    let token = localStorage.getItem("token");
    if (token != null && token != "") {
      next({
        name: "Home",
      });
    }
  }
  return next();
});

export default router;
